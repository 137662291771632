const getAcccessToken = () => {

    let CLIENT_ID = app_process.env.API_CLIENT_ID;
    let CLIENT_SECRET = app_process.env.API_CLIENT_SECRET;
    let AUDIENCE = app_process.env.API_AUDIENCE;

    return new Promise((resolve, reject) => {

        fetch(`https://dev-ze-bgu88.eu.auth0.com/oauth/token`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: 'POST',
            body: `grant_type=client_credentials&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&audience=${AUDIENCE}`
        })
            .then(response => response.json())
            .then(json => {
                    resolve(json);
            })
            .catch(error => {
                reject(error);
            });
    });
}

module.exports = {
    getAcccessToken
}