<script>
  import { Router, Route } from "svelte-routing";
  import Home from "./routes/Home.svelte";
  import Login from "./routes/Login.svelte";
  import Part from "./routes/Part.svelte";
  import NotFound from "./routes/NotFound.svelte";
  import Notifications from 'svelte-notifications';
  import jQuery from 'jquery';
  import initDt from 'datatables.net-bs4';
  initDt(jQuery);
</script>

<Notifications>
<Router>
  <Route path="/">
    <Login />
  </Route>
  <Route path="/login">
    <Login />
  </Route>
  <Route path="/home">
    <Home />
  </Route>
  <Route path="/part">
    <Part />
  </Route>
  <Route>
    <NotFound />
  </Route>
</Router>
</Notifications>
