let EXTERNAL_API_HOST = app_process.env.EXTERNAL_API_HOST;

const listParts = (access_token) => {
    return new Promise((resolve, reject) => {

        fetch(`https://${EXTERNAL_API_HOST}/ws/api/v1/parts`, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            },
            method: 'GET'
        })
            .then(response => response.json())
            .then(json => {
                    resolve(json);
            })
            .catch(error => {
                reject(error);
            });
    });
}

const listPartsByCustomerId = (customer_id, access_token) => {
    return new Promise((resolve, reject) => {

        fetch(`https://${EXTERNAL_API_HOST}/ws/api/v1/parts?customerId=${customer_id}`, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            },
            method: 'GET'
        })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                reject(error);
            });
    });
}

const deletePart = (partId, access_token) => {
    return new Promise((resolve, reject) => {

        fetch(`https://${EXTERNAL_API_HOST}/ws/api/v1/parts/${partId}`, {
            headers: {
                'Authorization': `Bearer ${access_token}`
            },
            method: 'DELETE'
        })
            .then(response => response.json())
            .then(json => {
                resolve(json);
            })
            .catch(error => {
                reject(error);
            });
    });
}

module.exports = {
    listParts,
    listPartsByCustomerId,
    deletePart
}