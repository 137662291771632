<script>
    import {onMount} from "svelte";
    import {deletePart, listParts, listPartsByCustomerId} from "../service/PartsService";
    import {isAuthenticated, jwt} from "../store/store";
    import {ColumnFilterInputs, Datatable, rows} from "svelte-simple-datatables";
    import {getNotificationsContext} from 'svelte-notifications';
    import {part} from '../store/Part';
    import {navigate} from "svelte-routing";
    import {getAcccessToken} from "../service/TokenService";
    import auth from "../service/authService";

    const {addNotification} = getNotificationsContext();

    const settings = {
        sortable: true,
        pagination: true,
        scrollY: false,
        rowPerPage: 15,
        columnFilter: false,
        css: true,
        labels: {
            search: 'Search...',
            filter: 'Filter',
            noRows: 'No entries was found',
            info: 'Showing {start} to {end} of {rows} entries',
            previous: 'Prev',
            next: 'Next',
        },
        blocks: {
            searchInput: true,
            paginationButtons: true,
            paginationRowCount: true,
        }
    };

    let data = [];
    let progressValue = 0;
    let loading = true;
    let direction = 'UP';
    let loadingInterval;
    let customerId = '';
    let auth0Client;
    let user;

    const startLoading = () => {
        loading = true;
        loadingInterval = setInterval(function () {
            direction === 'UP' ? progressValue += 10 : progressValue -= 2;
            if (progressValue === 100) {
                direction = 'DOWN';
            } else if (progressValue === 0) {
                direction = 'UP';
            }
        }, 80)
    }

    const stopLoading = () => {
        loading = false;
        clearInterval(loadingInterval);
        progressValue = 0;
        direction = 'UP';
    }

    const saveTemporaryData = (data) => {
        sessionStorage.setItem('temporaryData', JSON.stringify(data));
    }

    const cleanSessionData = () => {
        let n = sessionStorage.length;
        while(n--) {
            const key = sessionStorage.key(n);
            if(sessionStorage.getItem(key)) {
                sessionStorage.removeItem(key);
            }
        }
    }

    const getAllData = () => {
        startLoading();
        listParts($jwt)
            .then((resp) => {
                if (resp.error && resp.error.status === 401){
                    if (resp.error.name === 'TokenExpiredError'){
                        getAcccessToken().then((response) => {
                            jwt.setJwt(response.access_token);
                            sessionStorage.setItem('session', response.access_token);
                            listParts($jwt)
                                .then((resp) => {
                                    data = resp.parts;
                                    saveTemporaryData(data);
                                    stopLoading();
                                })
                        })
                    } else {
                        showNotificationFailure('Invalid Access Token, you will be redirected to login page');
                        setTimeout(async function () {
                            await cleanSessionData();
                            navigate("/login", {replace: true});
                        }, 8000);
                        stopLoading();
                    }
                } else {
                    data = resp.parts;
                    saveTemporaryData(data);
                    stopLoading();
                }
            })
            .catch(err => {
                console.log(err);
            });

    };

    const getDataByCustomerId = () => {
        startLoading();
        listPartsByCustomerId(customerId, $jwt)
            .then((resp) => {
                if (resp.status === 401){
                    if (resp.error.name === 'TokenExpiredError'){
                        getAcccessToken().then((response) => {
                            jwt.setJwt(response.access_token);
                            sessionStorage.setItem('session', response.access_token);
                            listParts($jwt)
                                .then((resp) => {
                                    data = resp.parts;
                                    saveTemporaryData(data);
                                    stopLoading();
                                })
                        })
                    }else{
                        showNotificationFailure('Invalid Access Token, you will be redirected to login page');
                        setTimeout(async function () {
                            await cleanSessionData();
                            navigate("/login", {replace: true});
                        }, 5000);
                    }
                } else {
                    data = resp.parts;
                    saveTemporaryData(data);
                    stopLoading();
                }
            })
            .catch((err) => {
                console.log(err);
            });

    };

    const checkLoged = async () => {
        auth0Client = await auth.createClient();
        window.magicToken ? sessionStorage.setItem('session', window.magicToken) : false;
        $isAuthenticated || sessionStorage.getItem('session') ? console.log('logging check successfully') : navigate("/login", {replace: true});
        sessionStorage.getItem('session') ? jwt.setJwt(sessionStorage.getItem('session')) : navigate("/login", {replace: true});
        user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
    }

    const logout = async () => {
        await auth.logout(auth0Client);
        await cleanSessionData();
        $isAuthenticated ? console.log('user is still authenticated') : navigate("/login", {replace: true});
    }

    const getTemporaryData = () => {
        startLoading()
        if (sessionStorage.getItem('temporaryData')) {
            data = JSON.parse(sessionStorage.getItem('temporaryData'));
        }
        stopLoading();
    }

    onMount(() => {
        checkLoged();
        getTemporaryData();
    });

    const showNotificationSuccess = () => {
        addNotification({
            text: 'Successful deletion',
            position: 'top-right',
            type: 'success',
            removeAfter: 3000
        });
    }

    const showNotificationFailure = (msg) => {
        addNotification({
            text: 'Failed due to ' + msg,
            position: 'top-right',
            type: 'danger',
            removeAfter: 8000
        });
    }

    const search = () => {
        if (customerId !== ''){
            getDataByCustomerId()
        }else{
            getAllData();
        }

        return true;
    }

    const search_using_event = e => {
        if (e.charCode === 13) {
            search();
        }
    }

    const search_using_button = () => {
        search();
    }

    const deleteRow = (row) => {
        if (confirm('Are you sure you want to delete part ' + row.partNumber)) {
            startLoading();
            deletePart(row._id, $jwt).then((resp) => {
                stopLoading();
                if (resp.error) {
                    showNotificationFailure(resp.error.name);
                } else {
                    data.splice(data.findIndex((obj) => obj._id === row._id), 1);
                    data = data;
                    showNotificationSuccess();
                    return false;
                }
            }).catch((err) => {
                console.log(err);
                return false;
            });
            return true;
        }
        return false;
    }

    const goToPart = (part_row) => {
        if (typeof (Storage) !== 'undefined') {
            sessionStorage.setItem('part', JSON.stringify(part_row));
        } else {
            console.log('Session storage is not available');
        }
        part.setPart(part_row);
        navigate("/part", {replace: false});
        return true;
    }
</script>

<main>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
            <a class="navbar-brand" href="/home">CNC Parts Internal Tests</a>
            <button aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation"
                    class="navbar-toggler"
                    data-bs-target="#navbarColor01" data-bs-toggle="collapse" type="button">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarColor01">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <a aria-current="page" class="nav-link active" href="/home">Home</a>
                    </li>
                </ul>
            </div>
            <div class="d-flex align-items-center">
                <!-- Name -->
                {#if user}
                    <span style="font-weight: lighter; margin-right: 10px">{user.name}</span>

                <!-- Avatar -->
                <a
                        class="dropdown-toggle d-flex align-items-center hidden-arrow"
                        href="#"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-mdb-toggle="dropdown"
                        aria-expanded="false"
                >
                    <img
                            src={user.picture}
                            class="rounded-circle"
                            height="32"
                            alt=""
                            loading="lazy"
                    />
                </a>
                <ul
                        class="dropdown-menu dropdown-menu-end"
                        aria-labelledby="navbarDropdownMenuLink"
                >
                    <li>
                        <a class="dropdown-item" href="#" on:click={logout}>Logout</a>
                    </li>
                </ul>
                {/if}
            </div>
        </div>
    </nav>
    <div class="container">
        <div class="row justify-content-end" style="padding-right: 12px;">
            <div class="form bg-white">
                <form class="d-flex input-group w-auto" on:submit|preventDefault>
                    <input
                            aria-describedby="search-addon"
                            aria-label="Search"
                            bind:value={customerId}
                            class="form-control rounded"
                            on:keypress={search_using_event}
                            placeholder="Search by Customer Id"
                            type="search"
                    />
                    <span class="input-group-text border-0" id="search-addon">
                        <button class="btn btn-link btn-sm link-primary" data-mdb-ripple-color="dark" on:click={search_using_button}
                                type="button">
                            <i class="fas fa-search" style="font-size: 16px;"></i>
                        </button>
                    </span>
                </form>
            </div>
        </div>

        <Datatable data={data} settings={settings}>
            <thead>
            <tr>
                <th class="sortable asc desc" data-key="_id">ID <span/></th>
                <th class="sortable asc desc" data-key="sfId">Salesforce Id<span/></th>
                <th class="sortable asc desc" data-key="customerId">Customer Id<span/></th>
                <th class="sortable asc desc" data-key="partNumber">Part Nr<span/></th>
                <th class="sortable asc desc" data-key="articleNr">Article Nr<span/></th>
                <th class="sortable asc desc" data-key="nameTechnicalDrawing">Name Technical Drawing<span/></th>
                <th class="sortable asc desc">Actions<span/></th>
            </tr>
            {#if loading}
                <tr>
                    <th class="loader" colspan="7">
                        <div class="progress">
                            <div
                                    class="progress-bar progress-bar-striped progress-bar-animated"
                                    role="progressbar"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                    style="width: {progressValue}%;"
                            ></div>
                        </div>
                    </th>
                </tr>
            {/if}

            <ColumnFilterInputs/>
            </thead>
            <tbody>
            {#each $rows as row}
                <tr>
                    <td>{row._id}</td>
                    <td>{row.sfId}</td>
                    <td>{row.customerId}</td>
                    <td>{row.partNumber}</td>
                    <td>{row.articleNr}</td>
                    <td>{row.nameTechnicalDrawing}</td>
                    <td>
                        <button type="button" class="table-action btn btn-link btn-sm" data-mdb-ripple-color="dark"
                                style="color: #00b74a" on:click={goToPart(row)}>
                            <i class="bi bi-pencil-square"></i>
                        </button>
                        <button type="button" class="table-action btn btn-link btn-sm" data-mdb-ripple-color="dark"
                                style="color: #f93154"
                                on:click={deleteRow(row)}>
                            <i class="bi bi-trash"></i>
                        </button>
                    </td>
                </tr>
            {/each}
            </tbody>
        </Datatable>
    </div>
</main>

<style lang="scss">
  button.table-action {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 100px;

    i {
      font-size: 16px;
    }
  }

  .container {
    padding-bottom: 5px;
  }

  .loader {
    padding: 0;
  }

  @media (min-width: 1400px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 95% !important;
    }
  }

  .form.bg-white {
    width: fit-content;
    padding: 1rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
  }
</style>
