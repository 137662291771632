import { writable, derived } from 'svelte/store';


const save = () => {
    const {subscribe, set} = writable(null);

    return {
        subscribe,
        setJwt: (jwt) => {
            set(jwt)
        }
    }
}

export const jwt = save();

export const isAuthenticated = writable(false);
export const user = writable({});
export const popupOpen = writable(false);
export const error = writable();