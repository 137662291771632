import { writable } from 'svelte/store'

const storePart = () => {
    const {subscribe, set} = writable(null);

    return {
        subscribe,
        setPart: (part) => {
            set(part)
        }
    }
}

const storeFiles = () => {
    const {subscribe, set} = writable([]);

    return {
        subscribe,
        setFiles: (files) => {
            set(files)
        }
    }
}

const part = storePart();
const files = storeFiles();

export {part, files};