<script>
    import {navigate} from "svelte-routing";
    import {getNotificationsContext} from 'svelte-notifications';
    import {getAcccessToken} from "../service/TokenService";
    import {onMount} from "svelte";
    import auth from "../service/authService";
    import {isAuthenticated, user} from "../store/store";

    const {addNotification} = getNotificationsContext();

    let auth0Client;

    onMount(async () => {
        auth0Client = await auth.createClient();

        isAuthenticated.set(await auth0Client.isAuthenticated());
        user.set(await auth0Client.getUser());
    });

    const login = async () => {
        await auth.loginWithPopup(auth0Client);
        if ($isAuthenticated) {
            // const token = await auth0Client.getTokenSilently();
            // console.log(await auth0Client.getIdTokenClaims()); //este me puede servir para autenticar en la api tambien
            sessionStorage.setItem('user', JSON.stringify($user));
            getAcccessToken().then((response) => {
                sessionStorage.setItem('session', response.access_token);
                navigate("/home", {replace: false});
            }).catch((error) => {
                console.error('Impossible to get access token');
                showNotificationFailure('Impossible to authenticate against api server');
            });
        } else {
            showNotificationFailure('Invalid credentials');
        }
    }

    const logout = async () => {
        await auth.logout(auth0Client);
        $isAuthenticated ? console.log('eeeeeee') : console.log('mierda')
    }

    const showNotificationFailure = (message) => {
        addNotification({
            text: message,
            position: 'top-center',
            type: 'danger',
            removeAfter: 3000
        });
    }

</script>

<main>
    <div class="d-flex align-items-center h-100">
        <div class="container">
            <div class="d-flex row justify-content-center">
                <div class="col-md-4 bg-white shadow-5-strong rounded-5 p-0">
                    <img alt="image" src="images/92288000_789170774940044.jpg"
                         style="width:100%; border-radius: 0.5rem">
                    <h4 style="text-align:center; color:#113953; font-weight: bold">Parts DB</h4>
                    <div class="d-flex row justify-content-center" style="width:100%; margin-bottom: 1rem;">
                        <button class="btn btn-primary btn-block" on:click={login} style="width:60%" type="submit">Sign
                            in
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<style lang="scss">
  main {
    height: 100%;
    width: 100%;
    background: #16222a; /* fallback for old browsers */
    background: -webkit-linear-gradient(
                    to right,
                    #16222a,
                    #3a6073
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
                    to right,
                    #16222a,
                    #3a6073
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
</style>
