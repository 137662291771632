<script>
    import {onMount} from "svelte";
    import {part} from "../store/Part";
    import {isAuthenticated, jwt} from "../store/store";
    import {navigate} from "svelte-routing";
    import jQuery from 'jquery';
    import { watchResize } from "svelte-watch-resize";
    import auth from "../service/authService";

    let auth0Client;
    let user;

    //Data
    let data = [];
    let revisionData = {};
    let files = [];
    let partnersMatched = [];
    let partnersPrices = [];
    let opportunityLines = [];
    let offerLines = [];
    let orderLines = [];
    let supplierOrderLines = [];

    const tableConfig = {
        "scrollX": true,
        "pageLength": 5,
        "lengthMenu": [5, 10, 25, 50, 75, 100]
    }

    //Elements
    let el_tableQuantities
    let el_tableFiles
    let el_tablePartnersMatched
    let el_partnersPrices
    let el_opportunityLines
    let el_offerLines
    let el_orderLines
    let el_supplierOrderLines

    //Tables
    let tableQuantities
    let tableFiles
    let tablePartnersMatched
    let tablePartnersPrices
    let tableOpportunityLines
    let tableOfferLines
    let tableOrderLines
    let tableSupplierOrderLines

    const updateArrays = () => {
        files = revisionData.files;
        partnersMatched = revisionData.partnersMatched;
        partnersPrices = revisionData.partnerPrices;
        opportunityLines = revisionData.opportunityLines;
        offerLines = revisionData.offerLines;
        orderLines = revisionData.orderLines;
        supplierOrderLines = revisionData.supplierOrderLines;
    }

    let loadPart = new Promise((resolve, reject) => {
        if ($part) {
            data = $part;
        } else {
            data = JSON.parse(sessionStorage.getItem('part'));
        }
        if (data.revisions.length > 0) {
            revisionData = data.revisions[data.revisions.length - 1];
        }
        updateArrays();
        resolve();
    });

    const checkLoged = async () => {
        auth0Client = await auth.createClient();
        $isAuthenticated || sessionStorage.getItem('session') ? console.log('logging check successfully') : navigate("/login", {replace: true});
        sessionStorage.getItem('session') ? jwt.setJwt(sessionStorage.getItem('session')) : navigate("/login", {replace: true});
        user = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null;
    }

    const logout = async () => {
        await auth.logout(auth0Client);
        let n = sessionStorage.length;
        while(n--) {
            const key = sessionStorage.key(n);
            if(sessionStorage.getItem(key)) {
                sessionStorage.removeItem(key);
            }
        }
        $isAuthenticated ? console.log('user is still authenticated') : navigate("/login", {replace: true});
    }

    onMount(() => {
        checkLoged();
        loadPart.then(async () => {
            tableQuantities = jQuery(el_tableQuantities).DataTable(tableConfig);
            await createDataTables();
        });
    })

    const updateTableInfo = (evt) => {
        return new Promise((resolve, reject) => {
            revisionData = data.revisions[evt.target.value];
            updateArrays();
            resolve();
        });
    }

    const destroyDataTables = () => {
        return new Promise((resolve, reject) => {
            tableFiles.destroy();
            tablePartnersMatched.destroy();
            tablePartnersPrices.destroy();
            tableOpportunityLines.destroy();
            tableOfferLines.destroy();
            tableOrderLines.destroy();
            tableSupplierOrderLines.destroy();
            resolve();
        });
    }

    const createDataTables = () => {
        return new Promise((resolve, reject) => {
            tableFiles = jQuery(el_tableFiles).DataTable(tableConfig);
            tablePartnersMatched = jQuery(el_tablePartnersMatched).DataTable(tableConfig);
            tablePartnersPrices = jQuery(el_partnersPrices).DataTable(tableConfig);
            tableOpportunityLines = jQuery(el_opportunityLines).DataTable(tableConfig);
            tableOfferLines = jQuery(el_offerLines).DataTable(tableConfig);
            tableOrderLines = jQuery(el_orderLines).DataTable(tableConfig);
            tableSupplierOrderLines = jQuery(el_supplierOrderLines).DataTable(tableConfig);
            resolve();
        });
    }

    const handleSelection = async (evt) => {
        await destroyDataTables();
        await updateTableInfo(evt);
        await createDataTables();
    }

    const handleResizing = async (el) => {
        tableQuantities.destroy();
        await destroyDataTables();
        tableQuantities = jQuery(el_tableQuantities).DataTable(tableConfig);
        await createDataTables();
    }

</script>

<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
        <a class="navbar-brand" href="/home">CNC Parts Internal Tests</a>
        <button aria-controls="navbarColor01" aria-expanded="false" aria-label="Toggle navigation"
                class="navbar-toggler"
                data-bs-target="#navbarColor01" data-bs-toggle="collapse" type="button">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarColor01">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a aria-current="page" class="nav-link active" href="/home">Home</a>
                </li>
            </ul>
        </div>
        <div class="d-flex align-items-center">
            <!-- Name -->
            {#if user}
                <span style="font-weight: lighter; margin-right: 10px">{user.name}</span>


                <!-- Avatar -->
                <a
                        class="dropdown-toggle d-flex align-items-center hidden-arrow"
                        href="#"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-mdb-toggle="dropdown"
                        aria-expanded="false"
                >
                    <img
                            src={user.picture}
                            class="rounded-circle"
                            height="32"
                            alt=""
                            loading="lazy"
                    />
                </a>
                <ul
                        class="dropdown-menu dropdown-menu-end"
                        aria-labelledby="navbarDropdownMenuLink"
                >
                    <li>
                        <a class="dropdown-item" href="#" on:click={logout}>Logout</a>
                    </li>
                </ul>
            {/if}
        </div>
    </div>
</nav>
<main use:watchResize={handleResizing}>
    <div class="row">
        <div class="col-md-3">
            <div class="card bg-light mb-3">
                <div class="card-header">PART ATTRIBUTES</div>
                <div class="card-body text-dark">
                    {#if data }
                        {#if data.framework}
                            <p class="card-text">
                                <small><em><span style="font-weight: bold">Quantity per Batch: </span></em> {data.quantityPerBatch ? data.quantityPerBatch : ''}</small>
                            </p>
                            <p class="card-text">
                                <small><em><span style="font-weight: bold">Duration for Framework Agreement: </span></em> {data.timeframe ? data.timeframe : ''}</small>
                            </p>
                            <p class="card-text">
                                <small><em><span style="font-weight: bold">Sample Quantity: </span></em> {data.sampleQuantity ? data.sampleQuantity : ''}</small>
                            </p>
                        {:else}
                            <p class="card-text">
                                <small><em><span style="font-weight: bold">Part Id: </span></em> {data._id ? data._id : ''}</small>
                            </p>
                            <p class="card-text">
                                <small><em><span style="font-weight: bold">SfId: </span></em> {data.sfId ? data.sfId : ''}</small>
                            </p>
                            <p class="card-text">
                                <small><em><span style="font-weight: bold">Customer Id: </span></em> {data.customerId ? data.customerId : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span style="font-weight: bold">Part Number: </span></em> {data.partNumber ? data.partNumber : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span style="font-weight: bold">Part Name: </span></em> {data.partName ? data.partName : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span style="font-weight: bold">Article Nr: </span></em> {data.articleNr ? data.articleNr : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span
                                        style="font-weight: bold">Name Technical Drawing: </span></em> {data.nameTechnicalDrawing ? data.nameTechnicalDrawing : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span style="font-weight: bold">Labeling: </span></em> {data.labeling ? data.labeling : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span
                                        style="font-weight: bold">Production Method: </span></em> {data.productionMethod ? data.productionMethod.toString() : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span
                                        style="font-weight: bold">Material Group: </span></em> {data.materialGroup ? data.materialGroup : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span
                                        style="font-weight: bold">Material Options: </span></em> {data.materialOptions ? data.materialOptions.toString() : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span
                                        style="font-weight: bold">Material Options Details: </span></em> {data.materialOptionsDetails ? data.materialOptionsDetails : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span style="font-weight: bold">Difficulty: </span></em> {data.difficulty ? data.difficulty : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span
                                        style="font-weight: bold">Additional Production Method: </span></em> {data.additionalProductionMethod ? data.additionalProductionMethod.toString() : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span
                                        style="font-weight: bold">Additional Production Method Details: </span></em> {data.additionalProductionMethodDetails ? data.additionalProductionMethodDetails : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span
                                        style="font-weight: bold">Target Price Partner: </span></em> {data.targetPricePartner ? data.targetPricePartner : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span
                                        style="font-weight: bold">Target Price Customer: </span></em> {data.targetPriceCustomer ? data.targetPriceCustomer : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span
                                        style="font-weight: bold">Comment For Supplier: </span></em> {data.commentForSupplier ? data.commentForSupplier : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span
                                        style="font-weight: bold">Comment For Customer: </span></em> {data.commentForCustomer ? data.commentForCustomer : ''}
                                </small>
                            </p>
                        {/if}
                    {:else}
                        <p class="card-text">
                            <em><span>No part was found.</span></em>
                        </p>
                    {/if}
                </div>
            </div>
            <div class="card bg-light mb-3">
                <div class="card-header">REVISION ATTRIBUTES</div>
                <div class="card-body text-dark">
                    { #if data }
                        {#if data.revisions && data.revisions.length > 0}
                            <div class="card-text"
                                 style="display:flex; flex-direction: row; flex-wrap: nowrap; margin-bottom: 0.5rem;">
                                <small style="width: 40%"><em><span style="font-weight: bold">Revision Nr: </span></em></small>
                                <select id="form1" style="width: 100%"
                                        class="form-control select-input placeholder-active active"
                                        aria-label="Select" on:change={handleSelection}>
                                    {#each data.revisions as revision, i}
                                        {#if (data.revisions.length - 1) === i}
                                            <option value={i} selected="selected">{revision.revisionNr}</option>
                                        {:else}
                                            <option value={i}>{revision.revisionNr}</option>
                                        {/if}
                                    {/each}
                                </select>
                            </div>
                            <p class="card-text">
                                <small><em><span
                                        style="font-weight: bold">RevisionCustomer Id: </span></em> {revisionData.revisionCustomerId ? revisionData.revisionCustomerId : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span
                                        style="font-weight: bold">Revision Id: </span></em> {revisionData.revisionId ? revisionData.revisionId : ''}
                                </small>
                            </p>
                            <p class="card-text">
                                <small><em><span style="font-weight: bold">X: </span></em> {revisionData.x ? revisionData.x : ''}</small>
                            </p>
                            <p class="card-text">
                                <small><em><span style="font-weight: bold">Y: </span></em> {revisionData.y ? revisionData.y : ''}</small>
                            </p>
                            <p class="card-text">
                                <small><em><span style="font-weight: bold">Z: </span></em> {revisionData.z ? revisionData.z : ''}</small>
                            </p>
                            <p class="card-text">
                                <small><em><span style="font-weight: bold">D: </span></em> {revisionData.d ? revisionData.d : ''}</small>
                            </p>
                            <p class="card-text">
                                <small><em><span style="font-weight: bold">Created Date: </span></em>{revisionData.createdDate ? revisionData.createdDate : ''}</small>
                            </p>
                            <p class="card-text">
                                <small><em><span style="font-weight: bold">Last Modified Date: </span></em>{revisionData.lastModifiedDate ? revisionData.lastModifiedDate : ''}</small>
                            </p>
                        {/if}
                    {:else}
                        <p class="card-text">
                            <em><span>No revisions were found.</span></em>
                        </p>
                    {/if}
                </div>
            </div>
            <div class="card bg-light mb-3">
                <div class="card-header">SUMMARY</div>
                <div class="card-body text-dark">
                    {#if data && data.summary}
                        <p class="card-text">
                            <small><em><span style="font-weight: bold">Cheapest Price: </span></em> {data.summary.cheapestPrice ? data.summary.cheapestPrice : ''} </small>
                        </p>
                        <p class="card-text">
                            <small><em><span style="font-weight: bold">Last Price Used: </span></em>{data.summary.lastPriceUsed ? data.summary.lastPriceUsed : ''} </small>
                        </p>
                        <p class="card-text">
                            <small><em><span style="font-weight: bold">Last Order Date: </span></em>{data.summary.lastOrderDate ? data.summary.lastOrderDate : ''} </small>
                        </p>
                        <p class="card-text">
                            <small><em><span style="font-weight: bold">Parts Ordered: </span></em>{data.summary.partsOrdered ? data.summary.partsOrdered : ''} </small>
                        </p>
                        <p class="card-text">
                            <small><em><span style="font-weight: bold">Number Of Orders: </span></em>{data.summary.numberOfOrders ? data.summary.numberOfOrders : ''} </small>
                        </p>
                        <p class="card-text">
                            <small><em><span style="font-weight: bold">Part Booking: </span></em>{data.summary.partBooking ? data.summary.partBooking : ''} </small>
                        </p>
                        <p class="card-text">
                            <small><em><span style="font-weight: bold">Part Revenue: </span></em> {data.summary.partRevenue ? data.summary.partRevenue : ''}</small>
                        </p>
                    {:else}
                        <p class="card-text">
                            <em><span>No summary were found.</span></em>
                        </p>
                    {/if}
                </div>
            </div>
        </div>
        <div class="col-md-9">
            <div class="card bg-light mb-3">
                <div class="card-header">QUANTITIES</div>
                <div class="card-body text-dark">
                    {#if data && data.quantities.length > 0}
                        <table bind:this={el_tableQuantities} class="display table table-striped" style="width:100%">
                            {#if data.framework}
                                <thead>
                                <tr>
                                    <th>Quantity Framework</th>
                                    <th>Blanket</th>
                                </tr>
                                </thead>
                                <tbody>
                                {#each data.quantities as row}
                                    <tr>
                                        <td>{row.quantityFramework ? row.quantityFramework : ''}</td>
                                        <td>{row.blanket ? row.blanket : ''}</td>
                                    </tr>
                                {/each}
                                </tbody>
                            {:else}
                                <thead>
                                <tr>
                                    <th>Quantity</th>
                                    <th>Delivery Time Per Quantity</th>
                                </tr>
                                </thead>
                                <tbody>
                                {#each data.quantities as row}
                                    <tr>
                                        <td>{row.quantity ? row.quantity : ''}</td>
                                        <td>{row.deliveryTimePerQuantity ? row.deliveryTimePerQuantity : ''}</td>
                                    </tr>
                                {/each}
                                </tbody>
                            {/if}
                        </table>
                    {:else}
                        <p class="card-text">
                            <em><span>No quantities were found.</span></em>
                        </p>
                    {/if}
                </div>
            </div>
            <div class="card bg-light mb-3">
                <div class="card-header">FILES</div>
                <div class="card-body text-dark">
                    {#if files.length > 0}
                        <table bind:this={el_tableFiles} class="display table table-striped" style="width:100%">
                            <thead>
                            <tr>
                                <th>Thumbnails</th>
                                <th>FileName</th>
                                <th>Path</th>
                                <th>Download</th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each files as row}
                                <tr>
                                    <td>{row.thumbnailsPath ? row.thumbnailsPath : ''}</td>
                                    <td>{row.fileName ? row.fileName : ''}</td>
                                    <td>{row.path ? row.path : ''}</td>
                                    <td style="text-align:center;"><button type="button" class="table-action btn btn-link btn-sm" data-mdb-ripple-color="dark">
                                        <i class="fas fa-cloud-download-alt"></i>
                                    </button></td>
                                </tr>
                            {/each}
                            </tbody>
                        </table>
                    {:else}
                        <p class="card-text">
                            <em><span>No files were found.</span></em>
                        </p>
                    {/if}
                </div>
            </div>
            <div class="card bg-light mb-3">
                <div class="card-header">PARTNERS MATCHED</div>
                <div class="card-body text-dark">
                    {#if partnersMatched.length > 0}
                        <table bind:this={el_tablePartnersMatched}
                               class="dta-table-partnersMatched display table table-striped" style="width:100%">
                            <thead>
                            <tr>
                                <th>Partner Name</th>
                                <th>Status</th>
                                <th>Match Ts</th>
                                <th>Price Submitted Ts</th>
                                <th>Rejected Reason Ts</th>
                                <th>Rejected Ts</th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each partnersMatched as row}
                                <tr>
                                    <td>{row.partnerName ? row.partnerName : ''}</td>
                                    <td>{row.status ? row.status : ''}</td>
                                    <td>{row.matchTS ? row.matchTS : ''}</td>
                                    <td>{row.priceSubmittedTS ? row.priceSubmittedTS : ''}</td>
                                    <td>{row.rejectedReason ? row.rejectedReason : ''}</td>
                                    <td>{row.rejectedTS ? row.rejectedTS : ''}</td>
                                </tr>
                            {/each}
                            </tbody>
                        </table>
                    {:else}
                        <p class="card-text">
                            <em><span>No Partners Matched were found.</span></em>
                        </p>
                    {/if}
                </div>
            </div>
            <div class="card bg-light mb-3">
                <div class="card-header">PARTNER PRICES</div>
                <div class="card-body text-dark">
                    {#if partnersPrices.length > 0}
                        <table bind:this={el_partnersPrices} class="dta-table display table table-striped"
                               style="width:100%">
                            <thead>
                            <tr>
                                <th>Partner Name</th>
                                <th>Quantity</th>
                                <th>Partner Price</th>
                                <th>Inc Surface</th>
                                <th>Material</th>
                                {#if data.framework}
                                    <th>Delivery Time Sample Quantity</th>
                                    <th>Delivery Time per Batch</th>
                                {:else}
                                    <th>Prod. Days</th>
                                {/if}
                                <th>Comment</th>
                                <th>Created Date</th>
                                <th>Last Price</th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each partnersPrices as row}
                                <tr>
                                    <td>{row.partnerName ? row.partnerName : ''}</td>
                                    <td>{row.quantity ? row.quantity : ''}</td>
                                    <td>{row.partnerPrice ? row.partnerPrice : ''}</td>
                                    {#if row.priceIncSurface !== null}
                                        <td><input type="checkbox" checked={row.priceIncSurface} disabled></td>
                                    {:else}
                                        <td></td>
                                    {/if}
                                    <td>{row.material ? row.material : ''}</td>
                                    {#if data.framework}
                                        <td>{row.deliveryTimeSampleQuantity ? row.deliveryTimeSampleQuantity : ''}</td>
                                        <td>{row.deliveryTimePerBatch ? row.deliveryTimePerBatch : ''}</td>
                                    {:else}
                                        <td>{row.productionDays ? row.productionDays : ''}</td>
                                    {/if}
                                    <td>{row.comment ? row.comment : ''}</td>
                                    <td>{row.createdDate ? row.createdDate : ''}</td>
                                    {#if row.isLastPrice !== null}
                                        <td><input type="checkbox" checked={row.isLastPrice} disabled></td>
                                    {:else}
                                        <td></td>
                                    {/if}
                                </tr>
                            {/each}
                            </tbody>
                        </table>
                    {:else}
                        <p class="card-text">
                            <em><span>No Partner Prices were found.</span></em>
                        </p>
                    {/if}
                </div>
            </div>
            <div class="card bg-light mb-3">
                <div class="card-header">OPPORTUNITY LINES</div>
                <div class="card-body text-dark">
                    {#if opportunityLines.length > 0}
                        <table bind:this={el_opportunityLines} class="dta-table display table table-striped"
                               style="width:100%">
                            <thead>
                            <tr>
                                <th>Opp Name</th>
                                <th>Opp Part</th>
                                <th>Status</th>
                                <th>Crated Date</th>
                                <th>Quantities</th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each opportunityLines as row}
                                <tr>
                                    <td>{row.oppPartName ? row.oppPartName : ''}</td>
                                    <td>{row.oppPartId ? row.oppPartId : ''}</td>
                                    <td>{row.status ? row.status : ''}</td>
                                    <td>{row.oppCreatedDate ? row.oppCreatedDate : ''}</td>
                                    <td>{row.quantities ? row.quantities.toString() : ''}</td>
                                </tr>
                            {/each}
                            </tbody>
                        </table>
                    {:else}
                        <p class="card-text">
                            <em><span>No Opportunity Lines were found.</span></em>
                        </p>
                    {/if}
                </div>
            </div>
            <div class="card bg-light mb-3">
                <div class="card-header">OFFER LINES</div>
                <div class="card-body text-dark">
                    {#if offerLines.length > 0}
                        <table bind:this={el_offerLines} class="dta-table display table table-striped"
                               style="width:100%">
                            <thead>
                            <tr>
                                <th>Offer Name</th>
                                <th>Offer Line</th>
                                <th>Status</th>
                                <th>Partner</th>
                                <th>Material</th>
                                <th>Quantity</th>
                                <th>Partner Price</th>
                                <th>Markup (%)</th>
                                <th>Part Price</th>
                                <th>Discount (%)</th>
                                <th>Total Part Price</th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each offerLines as row}
                                <tr>
                                    <td>{row.offerName ? row.offerName : ''}</td>
                                    <td>{row.offerLineId ? row.offerLineId : ''}</td>
                                    <td>{row.status ? row.status : ''}</td>
                                    <td>{row.partnerName ? row.partnerName : ''}</td>
                                    <td>{row.material ? row.material : ''}</td>
                                    <td>{row.quantity ? row.quantity : ''}</td>
                                    <td>{row.partnerPrice ? row.partnerPrice : ''}</td>
                                    <td>{row.markup ? row.markup : ''}</td>
                                    <td>{row.partPriceNet ? row.partPriceNet : ''}</td>
                                    <td>{row.discount ? row.discount : ''}</td>
                                    <td>{row.totalPartPriceNetAfterDiscount ? row.totalPartPriceNetAfterDiscount : ''}</td>
                                </tr>
                            {/each}
                            </tbody>
                        </table>
                    {:else}
                        <p class="card-text">
                            <em><span>No Offer Lines were found.</span></em>
                        </p>
                    {/if}
                </div>
            </div>
            <div class="card bg-light mb-3">
                <div class="card-header">ORDER LINES</div>
                <div class="card-body text-dark">
                    {#if orderLines.length > 0}
                        <table bind:this={el_orderLines} class="dta-table display table table-striped"
                               style="width:100%">
                            <thead>
                            <tr>
                                <th>Order Name</th>
                                <th>Order Line</th>
                                <th>Status</th>
                                <th>Partner</th>
                                <th>Material</th>
                                <th>Quantity</th>
                                <th>Partner Price</th>
                                <th>Markup (%)</th>
                                <th>Part Price</th>
                                <th>Discount (%)</th>
                                <th>Total Part Price</th>
                                <th>Framework Order</th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each orderLines as row}
                                <tr>
                                    <td>{row.offerName ? row.offerName : ''}</td>
                                    <td>{row.offerLineId ? row.offerLineId : ''}</td>
                                    <td>{row.status ? row.status : ''}</td>
                                    <td>{row.partnerName ? row.partnerName : ''}</td>
                                    <td>{row.material ? row.material : ''}</td>
                                    <td>{row.quantity ? row.quantity : ''}</td>
                                    <td>{row.partnerPrice ? row.partnerPrice : ''}</td>
                                    <td>{row.markup ? row.markup : ''}</td>
                                    <td>{row.partPriceNet ? row.partPriceNet : ''}</td>
                                    <td>{row.discount ? row.discount : ''}</td>
                                    <td>{row.totalPartPriceNetAfterDiscount ? row.totalPartPriceNetAfterDiscount : ''}</td>
                                    {#if row.frameworkOrder !== null}
                                        <td><input type="checkbox" checked={row.frameworkOrder} disabled></td>
                                    {:else}
                                        <td></td>
                                    {/if}
                                </tr>
                            {/each}
                            </tbody>
                        </table>
                    {:else}
                        <p class="card-text">
                            <em><span>No Order Lines were found.</span></em>
                        </p>
                    {/if}
                </div>
            </div>
            <div class="card bg-light mb-3">
                <div class="card-header">SUPPLIER ORDER LINES</div>
                <div class="card-body text-dark">
                    {#if supplierOrderLines.length > 0}
                        <table bind:this={el_supplierOrderLines} class="dta-table display table table-striped"
                               style="width:100%">
                            <thead>
                            <tr>
                                <th>Supplier Order Name</th>
                                <th>Supplier Order Line</th>
                                <th>Partner</th>
                                <th>Status</th>
                                <th>Quantity</th>
                                <th>Partner Price</th>
                                <th>Total Part Price</th>
                                <th>Created Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {#each supplierOrderLines as row}
                                <tr>
                                    <td>{row.supplierOrderName ? row.supplierOrderName : ''}</td>
                                    <td>{row.supplierOrderId ? row.supplierOrderId : ''}</td>
                                    <td>{row.partnerId ? row.partnerId : ''}</td>
                                    <td>{row.status ? row.status : ''}</td>
                                    <td>{row.quantity ? row.quantity : ''}</td>
                                    <td>{row.partPrice ? row.partPrice : ''}</td>
                                    <td>{row.totalPartPrice ? row.totalPartPrice : ''}</td>
                                    <td>{row.createdDate ? row.createdDate : ''}</td>
                                </tr>
                            {/each}
                            </tbody>
                        </table>
                    {:else}
                        <p class="card-text">
                            <em><span>No Supplier Order Lines were found.</span></em>
                        </p>
                    {/if}
                </div>
            </div>
        </div>
    </div>
</main>

<svelte:head>
    <link href="https://cdn.datatables.net/v/bs4/dt-1.10.25/datatables.min.css" rel="stylesheet" type="text/css"/>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/5.0.1/js/bootstrap.bundle.min.js"
            type="text/javascript"></script>
</svelte:head>
<style lang="scss">
  main {
    padding-left: 20px;
    padding-right: 20px;
  }

  p {
    margin-bottom: .5rem;
  }

  .card-header {
    font-weight: bold;
  }

  select {
    -webkit-appearance: auto !important;
    -moz-appearance: auto !important;
    appearance: auto !important;
  }
  th, td {
    text-align: center;
  }
  button.table-action {
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 100px;

    i {
      font-size: 16px;
    }
  }
</style>